import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout/Layout';
import SEO from 'components/SEO/SEO';
import Hero from 'components/Hero/Hero';

const NotFoundPage = ({ data, location }) => {
  return (
    <Layout location={location} type="standard">
      <SEO meta={data.notFoundYaml.metaTags} title={data.notFoundYaml.title} />
      <Hero data={data.notFoundYaml.hero} />
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query NotFoundPageQuery {
    notFoundYaml {
      id
      title
      metaTags {
        name
        content
      }
      hero {
        size
        title
        subtitle
        heroImage {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        primaryButton {
          text
          url
        }
        separator
      }
    }
  }
`;
